


















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Checkbox extends Vue {
  @Prop({}) defaultValue!: string
  @Prop({}) rules!: any[]

  text = ''

  @Watch('defaultValue', { immediate: true }) onDefaultValueChange(val: string) {
    this.text = val
  }

  @Watch('text', { immediate: true }) onTextChange(val: string) {
    this.$emit('onTextChange', val)
  }
}
